import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { CentraSelection, CentraSelectionPaymentMethod } from '@made-people/centra-models/src/shop-api/CentraSelection'
import { CheckoutAddressField, FormActionStripe, FormActionPaypal, PaypalFinilizeResponse, RootState, StripeFinilizeResponse, isStripeFinilizeResponse, isCentraErrorResponse, CentraErrorResponse, isFormActionPaypal, isFormActionStripe } from './types'
import isAxiosError from '~/types/is-axios-error'
import logError from '~/utils/log-error'

export interface PaymentMethodsAvailable {
  [key: string]: CentraSelectionPaymentMethod
}

export function state (): RootState['checkout'] {
  return {
    isLoading: false,
    isLoadingPaypalButton: false,
    isLoadingStripeButton: false,
    address: {},
    shippingAddress: {},
    errors: undefined,
    shipToBillingAddress: true,
    klarnaCheckoutSnippet: undefined,
    stripeData: undefined, // Data used for injecting Stripe PI button
    stripeError: false, // Backend call responded but was erroneous
    paypalData: undefined, // Data used for injecting Paypal commerce button
    paypalError: false, // Backend call responded but was erroneous
  }
}

export const getters: GetterTree<RootState['checkout'], RootState> = {
  isLoading: state => state.isLoading,
  isLoadingPaypalButton: state => state.isLoadingPaypalButton,
  isLoadingStripeButton: state => state.isLoadingStripeButton,
  address: state => state.address,
  shippingAddress: state => state.shippingAddress,
  errors: state => state.errors,
  shipToBillingAddress: state => state.shipToBillingAddress,
  /**
   * Get the checkout selection
   *
   * This may return undefined even if a cart exists
   */
  checkout: (_state, _getters, rootState) => {
    const selection = rootState['centra-cart']?.selection

    if (typeof selection === 'undefined') {
      return undefined
    }

    if (selection.items.length < 1) {
      // A selection without items is not a checkout
      return undefined
    }

    return selection
  },
  /**
   * This returns the selected payment method instance code, not the code for the payment method
   *
   * For example, it would return 'paypal-checkout-us' and not 'paypal_checkout'.
   */
  selectedPaymentMethodCode: (_state, getters): string | undefined => {
    const checkout: CentraSelection | undefined = getters.checkout

    if (typeof checkout === 'undefined') {
      return undefined
    }

    const paymentMethodsAvailable: CentraSelectionPaymentMethod[] | undefined =
      getters.checkout.paymentMethodsAvailable

    if (typeof paymentMethodsAvailable === 'undefined') {
      return undefined
    }

    const paymentMethod: string | null = getters.checkout.paymentMethod

    if (!paymentMethod) {
      // Return first available payment method if none is selected
      return Object.keys(paymentMethodsAvailable)[0]
    }

    return paymentMethod
  },
  selectedPaymentMethod: (_state, getters): CentraSelectionPaymentMethod | undefined => {
    const checkout: CentraSelection | undefined = getters.checkout

    if (typeof checkout === 'undefined') {
      return undefined
    }

    const paymentMethodCode: string | undefined =
      getters.selectedPaymentMethodCode

    if (typeof paymentMethodCode === 'undefined') {
      return undefined
    }

    return checkout.paymentMethodsAvailable[paymentMethodCode]
  },
  paymentMethodsAvailable (_, getters): PaymentMethodsAvailable | undefined {
    const checkout: CentraSelection | undefined = getters.checkout

    if (typeof checkout === 'undefined') {
      return undefined
    }

    return checkout.paymentMethodsAvailable
  },
  adyenDropInPaymentMethod (_, getters): CentraSelectionPaymentMethod | undefined {
    const paymentMethodsAvailable: PaymentMethodsAvailable | undefined = getters.paymentMethodsAvailable

    if (typeof paymentMethodsAvailable === 'undefined') {
      return undefined
    }

    return Object.values(paymentMethodsAvailable).find(t => t.paymentMethodType === 'adyen_drop_in')
  },
  adyenDropInIsSelected: (_state, getters): boolean => {
    const selectedPaymentMethod: CentraSelectionPaymentMethod | undefined =
      getters.selectedPaymentMethod

    if (typeof selectedPaymentMethod === 'undefined') {
      return false
    }

    const adyenDropInPaymentMethod: CentraSelectionPaymentMethod = getters.adyenDropInPaymentMethod

    if (typeof adyenDropInPaymentMethod === 'undefined') {
      return false
    }

    return selectedPaymentMethod.paymentMethodType === adyenDropInPaymentMethod.paymentMethodType
  },
  klarnaCheckoutPaymentMethod (_, getters): CentraSelectionPaymentMethod | undefined {
    const paymentMethodsAvailable: PaymentMethodsAvailable | undefined = getters.paymentMethodsAvailable

    if (typeof paymentMethodsAvailable === 'undefined') {
      return undefined
    }

    return Object.values(paymentMethodsAvailable).find(t => t.paymentMethodType === 'klarna_checkout')
  },
  klarnaCheckoutIsSelected: (_state, getters): boolean => {
    const selectedPaymentMethod: CentraSelectionPaymentMethod | undefined =
      getters.selectedPaymentMethod

    if (typeof selectedPaymentMethod === 'undefined') {
      return false
    }

    const klarnaCheckoutPaymentMethod: CentraSelectionPaymentMethod = getters.klarnaCheckoutPaymentMethod

    if (typeof klarnaCheckoutPaymentMethod === 'undefined') {
      return false
    }

    return selectedPaymentMethod.paymentMethodType === klarnaCheckoutPaymentMethod.paymentMethodType
  },
  klarnaCheckoutSnippet (state) {
    return state.klarnaCheckoutSnippet
  },
  paypalCommercePaymentMethod (_, getters): CentraSelectionPaymentMethod | undefined {
    const paymentMethodsAvailable: PaymentMethodsAvailable | undefined = getters.paymentMethodsAvailable

    if (typeof paymentMethodsAvailable === 'undefined') {
      return undefined
    }

    return Object.values(paymentMethodsAvailable).find(t => t.paymentMethod === 'paypal-commerce-checkout')
  },
  paypalCommerceIsSelected: (_state, getters): boolean => {
    const selectedPaymentMethod: CentraSelectionPaymentMethod | undefined =
      getters.selectedPaymentMethod

    if (typeof selectedPaymentMethod === 'undefined') {
      return false
    }

    const paypalCommercePaymentMethod: CentraSelectionPaymentMethod = getters.paypalCommercePaymentMethod

    if (typeof paypalCommercePaymentMethod === 'undefined') {
      return false
    }

    return selectedPaymentMethod.paymentMethodType === paypalCommercePaymentMethod.paymentMethodType
  },
  stripePaymentOption (_state, _getters, rootState) {
    const selection = rootState['centra-cart']?.selection

    if (typeof selection === 'undefined' || typeof selection.paymentMethodsAvailable === 'undefined') {
      return false
    }

    return Object
      .values(selection.paymentMethodsAvailable)
      .find(el => el.paymentMethodType === 'stripe_payment_intents')
  },
  paypalBuyNowPaymentOption (_state, _getters, rootState) {
    const selection = rootState['centra-cart']?.selection

    if (typeof selection === 'undefined' || typeof selection.paymentMethodsAvailable === 'undefined') {
      return false
    }

    return Object
      .values(selection.paymentMethodsAvailable)
      .find(el => el.paymentMethodType === 'paypal_commerce' && el.paymentMethod === 'paypal-commerce-button')
  },
  paypalError (state) {
    return state.paypalError
  },
  paypalDataId (state) {
    return state.paypalData?.formFields?.id
  },
  stripeError (state) {
    return state.stripeError
  },
  stripeHtml (state) {
    return state.stripeData?.formHtml ?? ''
  },
  stripePaymentData (state) {
    if (typeof state.stripeData?.formFields?.stripeParameters === 'string') {
      try {
        return JSON.parse(state.stripeData.formFields.stripeParameters)
      } catch (e) {
        console.error('Could not parse stripeParameters', e)
      }
    }
    return undefined
  },
  stripePublishableKey (state) {
    return state.stripeData?.formFields?.publishableKey
  },
}

export const mutations: MutationTree<RootState['checkout']> = {
  isLoading: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  isLoadingPaypalButton: (state, isLoadingPaypalButton: boolean) => {
    state.isLoadingPaypalButton = isLoadingPaypalButton
  },
  isLoadingStripeButton: (state, isLoadingStripeButton: boolean) => {
    state.isLoadingStripeButton = isLoadingStripeButton
  },
  address: (state, address: CheckoutAddressField) => {
    state.address = address
  },
  shippingAddress: (state, shippingAddress: CheckoutAddressField) => {
    state.shippingAddress = shippingAddress
  },
  errors: (state, errors: any) => {
    state.errors = errors
  },
  shipToBillingAddress (state, shipToBillingAddress: boolean) {
    state.shipToBillingAddress = shipToBillingAddress
  },
  klarnaCheckoutSnippet (state, klarnaCheckoutSnippet?: string) {
    state.klarnaCheckoutSnippet = klarnaCheckoutSnippet
  },
  stripeData (state, stripeData: FormActionStripe) {
    state.stripeData = stripeData
  },
  stripeError (state, stripeError: boolean) {
    state.stripeError = stripeError
  },
  paypalData (state, paypalData: FormActionPaypal) {
    state.paypalData = paypalData
  },
  paypalError (state, paypalError: boolean) {
    state.paypalError = paypalError
  },
}

export const actions: ActionTree<RootState['checkout'], RootState> = {
  fetchCheckout ({ dispatch }): Promise<void> {
    return dispatch('centra-cart/fetchCart', {}, { root: true })
  },
  /**
   * Call `CentraCheckoutScript.suspend` _if_ the script exists
   *
   * This should not be called directly from others than the checkout store since there
   * are conditions for when suspend/resume should be called and this method does not
   * check them.
   */
  async _callCheckoutScriptSuspend (): Promise<void> {
    if (window.CentraCheckout) {
      await window.CentraCheckout.suspend()
    }

    return Promise.resolve()
  },
  /**
   * Call `CentraCheckoutScript.resume` _if_ the script exists
   *
   * This should not be called directly from others than the checkout store since there
   * are conditions for when suspend/resume should be called and this method does not
   * check them.
   */
  async _callCheckoutScriptResume (): Promise<void> {
    if (window.CentraCheckout) {
      await window.CentraCheckout.resume()
    }

    return Promise.resolve()
  },
  async startLoading ({ commit, getters, dispatch }): Promise<void> {
    commit('isLoading', true)

    if (getters.klarnaCheckoutIsSelected) {
      // Suspend and resume the centra checkout script if KCO, Ingrid or Qliro One is used
      // @see https://docs.centra.com/fe-development/checkoutscript#suspend-and-resume
      await dispatch('_callCheckoutScriptSuspend')
    }

    return Promise.resolve()
  },
  async stopLoading ({ commit, dispatch, getters }): Promise<void> {
    // TODO: What happens if this was a change from klarna(suspend was called) to not klarna(resume will not be called)?
    if (getters.klarnaCheckoutIsSelected) {
      // Suspend and resume the centra checkout script if KCO, Ingrid or Qliro One is used
      // @see https://docs.centra.com/fe-development/checkoutscript#suspend-and-resume
      await dispatch('_callCheckoutScriptResume')
    }

    commit('isLoading', false)

    return Promise.resolve()
  },
  startLoadingPaypalButton ({ commit }): Promise<void> {
    commit('isLoadingPaypalButton', true)
    return Promise.resolve()
  },
  stopLoadingPaypalButton ({ commit }): Promise<void> {
    commit('isLoadingPaypalButton', false)
    return Promise.resolve()
  },
  startLoadingStripeButton ({ commit }): Promise<void> {
    commit('isLoadingStripeButton', true)
    return Promise.resolve()
  },
  stopLoadingStripeButton ({ commit }): Promise<void> {
    commit('isLoadingStripeButton', false)
    return Promise.resolve()
  },
  async updateIfKlarnaSelected ({ getters, dispatch }): Promise<void> {
    if (getters.klarnaCheckoutIsSelected) {
      // Suspend and resume the centra checkout script if KCO, Ingrid or Qliro One is used
      // @see https://docs.centra.com/fe-development/checkoutscript#suspend-and-resume
      await dispatch('_callCheckoutScriptResume')
    }
  },
  updateAddress ({ state, commit }, address: CheckoutAddressField): Promise<void> {
    commit('address', { ...state.address, ...address })
    return Promise.resolve()
  },
  updateShippingAddress ({ state, commit }, shippingAddress: CheckoutAddressField): Promise<void> {
    commit('shippingAddress', { ...state.shippingAddress, ...shippingAddress })
    return Promise.resolve()
  },
  /**
   * Update checkout fields by calling the backend
   *
   * This is some magic stuff centra is doing to sync payment methods etc. That's why
   * 'fields' doesn't have a type.
   */
  async updateCheckoutFields ({ dispatch, getters }, fields: unknown): Promise<void> {
    console.debug('checkout.updatedFields', fields)

    try {
      await this.$backendApi.put('cart/checkout/update-fields', fields)

      if (getters.adyenDropInIsUsed) {
        return
      }

      await dispatch('fetchCheckout')
    } catch (error) {
      console.error('Failed to update fields', error)

      if (error instanceof Error) {
        throw error
      } else if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
        throw new Error('Failed to update fields' + error.message)
      } else {
        throw new Error('Failed to update fields')
      }
    }
  },
  /**
   * Set shipping method code and do all other things that are required when the shipping method changes
   *
   * This implementation is currently _only_ tested with klarna.
   */
  async setShippingMethod ({ dispatch }, shippingMethodCode: string): Promise<void> {
    console.debug(`setting shipping method to ${shippingMethodCode}`)

    try {
      await dispatch('startLoading')

      if (window.CentraCheckout) {
        await window.CentraCheckout.suspend()
      }

      await this.$backendApi.put('/cart/checkout/shipping-method', {
        shippingMethodCode,
      })
    } catch (err) {
      console.error(err)
    } finally {
      if (window.CentraCheckout) {
        await window.CentraCheckout.resume()
      }

      await dispatch('fetchCheckout')
      await dispatch('stopLoading')
    }
  },

  /**
   * Set checkout country in the backend
   *
   * This updates the selection country in centra, including all address countries.
   */
  async setCountry ({ dispatch }, countryCode: string): Promise<void> {
    try {
      await dispatch('startLoading')

      if (window.CentraCheckout) {
        await window.CentraCheckout.suspend()
      }

      await this.$backendApi.put('/cart/checkout/country', {
        countryCode,
      })
    } catch (err) {
      console.error(err)
    } finally {
      if (window.CentraCheckout) {
        await window.CentraCheckout.resume()
      }

      await dispatch('fetchCheckout')
      await dispatch('stopLoading')
    }
  },
  /**
   * Set error messages from payment method responses. This is used to check for errors that comes from Adyen drop-in.
   */
  setErrors ({ commit }, errors: unknown): Promise<void> {
    commit('errors', errors)
    return Promise.resolve()
  },
  /**
   * Toggle shipping and billing address
   */
  shipToBillingAddress ({ commit, state }): Promise<void> {
    commit('shipToBillingAddress', !state.shipToBillingAddress)
    return Promise.resolve()
  },
  /**
   * Start Klarna session
   */
  async startKlarnaSession ({ getters, dispatch, rootState }) {
    const paymentMethodsAvailable: PaymentMethodsAvailable | undefined = getters.paymentMethodsAvailable

    if (typeof paymentMethodsAvailable === 'undefined') {
      return
    }

    try {
      await dispatch('startLoading')
      const response = await this.$backendApi.put(
        `/cart/checkout/${getters.selectedPaymentMethod.paymentMethodType}/session`,
        {
          whitelistedQueryParams: rootState.frontend.whitelistedQueryParams,
          paymentMethodCode: getters.selectedPaymentMethodCode
        }
      )

      await dispatch('fetchCheckout')
      return response.data
    } catch (err) {
      console.error(err)
    } finally {
      await dispatch('stopLoading')
    }

    return undefined
  },
  /**
   * Set Klarna checkout snippet HTML for success page
   */
  setKlarnaCheckoutSnippet ({ commit }, klarnaCheckoutSnippet) {
    commit('klarnaCheckoutSnippet', klarnaCheckoutSnippet)
  },
  // Fetches stripe html to inject
  async initiateStripePayment ({ commit, getters, rootState, rootGetters, dispatch }) {
    const stripePaymentOption: CentraSelectionPaymentMethod | undefined = getters.stripePaymentOption

    if (typeof stripePaymentOption === 'undefined') {
      return
    }

    dispatch('startLoadingStripeButton')

    const response = await this.$backendApi.put<FormActionStripe>('/cart/checkout/stripe/session', {
      whitelistedQueryParams: rootState.frontend.whitelistedQueryParams,
      paymentMethod: stripePaymentOption.paymentMethod,
      state: rootGetters['centra-cart/cart']?.countryState,
      baseUrl: process.env.BASE_URL,
    }).then((response) => {
      commit('stripeError', false)
      return response.data
    }).catch((error) => {
      if (isAxiosError<CentraErrorResponse>(error) && isCentraErrorResponse(error.response?.data)) {
        return { errors: error.response?.data.errors }
      } else {
        logError.call(this, error, 'Failed to initialize Stripe Payment: ')
      }
      return undefined
    })

    if (typeof response === 'undefined' || isCentraErrorResponse(response)) {
      dispatch('stopLoading')
      commit('stripeError', true)
      commit('stripeData', undefined)
    } else if (isFormActionStripe(response)) {
      // Should not be saved
      // @ts-expect-error
      delete response.formFields.clientSecret
      commit('stripeData', response)
    }
  },
  async finalizeStripePayment ({ dispatch }, { billingAddress, shippingAddress, paymentMethod, paymentMethodSpecificFields }) {
    dispatch('startLoading')
    const response = await this.$backendApi.put<StripeFinilizeResponse>('/cart/checkout/stripe/session/finalize', {
      address: billingAddress,
      shippingAddress,
      paymentMethod,
      paymentMethodSpecificFields,
    }).then((response) => {
      return response.data
    }).catch((error) => {
      if (isAxiosError<CentraErrorResponse>(error)) {
        return { errors: error.response?.data?.errors }
      } else {
        logError.call(this, error, 'Failed to finalize Stripe Payment: ')
      }
      return undefined
    })

    if (typeof response === 'undefined' || isCentraErrorResponse(response)) {
      dispatch('stopLoading')
      return response
    } else if (isStripeFinilizeResponse(response)) {
      return { url: response.url }
      // Will not stop loading if action is redirect, since Vuex state will be reset anyway when mutating window.location
    }
  },
  // Fetches paypal html to inject
  async initiatePaypalBuyNowPayment ({ commit, getters, rootState, rootGetters, dispatch }) {
    const paypalBuyNowPaymentOption: CentraSelectionPaymentMethod | undefined = getters.paypalBuyNowPaymentOption
    if (typeof paypalBuyNowPaymentOption === 'undefined') {
      return
    }

    dispatch('startLoadingPaypalButton')

    const response = await this.$backendApi.put<FormActionPaypal>('/cart/checkout/paypal_commerce/session', {
      whitelistedQueryParams: rootState.frontend.whitelistedQueryParams,
      paymentMethodCode: paypalBuyNowPaymentOption.paymentMethod,
      state: rootGetters['centra-cart/cart'].countryState,
      baseUrl: process.env.BASE_URL,
    }).then((response) => {
      commit('paypalError', false)
      return response.data
    }).catch((error) => {
      if (isAxiosError<CentraErrorResponse>(error) && isCentraErrorResponse(error.response?.data)) {
        return { errors: error.response?.data.errors }
      } else {
        logError.call(this, error, 'Failed to initialize Paypal Payment: ')
      }
      return undefined
    })

    if (typeof response === 'undefined' || isCentraErrorResponse(response)) {
      dispatch('stopLoading')
      commit('paypalError', true)
      commit('paypalData', undefined)
    } else if (isFormActionPaypal(response)) {
      // Should not be saved
      // @ts-expect-error
      delete response.formFields.clientSecret
      commit('paypalData', response)
    }
  },
  finalizePaypalBuyNowPayment ({ dispatch }, payload) {
    dispatch('startLoading')
    return this.$backendApi
      .put<PaypalFinilizeResponse>('/cart/checkout/paypal_commerce/session/finalize', payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (isAxiosError<CentraErrorResponse>(error) && isCentraErrorResponse(error.response?.data)) {
          return { errors: error.response!.data.errors }
        } else {
          return { unknownError: error?.response?.data ?? error }
        }
      })
  },
}
